import { render, staticRenderFns } from "./color_number.vue?vue&type=template&id=25c1728d&scoped=true&"
import script from "./color_number.vue?vue&type=script&lang=js&"
export * from "./color_number.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c1728d",
  null
  
)

export default component.exports