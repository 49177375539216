var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"index-name":"School","search-client":_vm.searchClient}},[_c('ais-configure',{attrs:{"hitsPerPage":6,"distinct":true,"filters":_vm.getFilters(_vm.categoryNames, _vm.subCategoryNames, _vm.city)}}),_vm._v(" "),_c('ais-search-box',{attrs:{"placeholder":"Recherche une formation","autofocus":true,"show-loading-indicator":true,"class-names":{
      'ais-SearchBox-form': 'cover__search-bar-programs',
      'ais-SearchBox-input': 'search-bar search-bar__products',
      'ais-SearchBox-submit': 'btn btn--big btn--primary search-bar__button',
      'ais-SearchBox-reset': 'hidden',
    }}},[_c('div',{attrs:{"slot":"submit-icon"},slot:"submit-icon"},[_c('i',{staticClass:"fas fa-search text--white fa-flip-horizontal"})])]),_vm._v(" "),_c('ais-panel',[_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
return _c('div',{staticClass:"products"},[_c('div',{staticClass:"flex flex--wrap flex--between"},_vm._l((items),function(item){return _c('div',{key:item.objectID,staticClass:"school__small"},[_c('school',{attrs:{"establishment":item,"break-size":20,"track":true,"track-type":"Program"}})],1)}),0)])}}])}),_vm._v(" "),_c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var currentRefinement = ref.currentRefinement;
        var nbPages = ref.nbPages;
        var pages = ref.pages;
        var isFirstPage = ref.isFirstPage;
        var isLastPage = ref.isLastPage;
        var refine = ref.refine;
        var createURL = ref.createURL;
return _c('ul',{staticClass:"pagination"},[(!isFirstPage)?_c('li',{staticClass:"list-link"},[_c('p',{staticClass:"link-text"},[_vm._v("\n            Précédent\n          ")]),_vm._v(" "),_c('a',{staticClass:"link link--option",attrs:{"href":createURL(currentRefinement - 1)},on:{"click":function($event){$event.preventDefault();refine(currentRefinement - 1);
              _vm.delayToTop();}}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.$mq == 'tablet' || _vm.$mq == 'phone'
            ? pages.slice(
                currentRefinement == 0
                  ? currentRefinement
                  : currentRefinement - 1,
                currentRefinement == 0
                  ? currentRefinement + 3
                  : currentRefinement + 1
              )
            : pages),function(page){return _c('li',{key:page,staticClass:"list-link",style:({
            backgroundColor: page === currentRefinement ? '#868c98' : '',
          })},[_c('p',{staticClass:"link-text",style:({
              fontWeight: page === currentRefinement ? 'bold' : '',
              color: page === currentRefinement ? '#fff' : '',
            })},[_vm._v("\n            "+_vm._s(page + 1)+"\n          ")]),_vm._v(" "),_c('a',{staticClass:"link link--option",attrs:{"href":createURL(page)},on:{"click":function($event){$event.preventDefault();refine(page);
              _vm.delayToTop();}}})])}),_vm._v(" "),(!isLastPage)?_c('li',{staticClass:"list-link"},[_c('p',{staticClass:"link-text"},[_vm._v("\n            Suivant\n          ")]),_vm._v(" "),_c('a',{staticClass:"link link--option",attrs:{"href":createURL(currentRefinement + 1)},on:{"click":function($event){$event.preventDefault();refine(currentRefinement + 1);
              _vm.delayToTop();}}})]):_vm._e()],2)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }