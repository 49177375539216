var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"index-name":"Program","search-client":_vm.searchClient}},[_c('ais-configure',{attrs:{"hitsPerPage":1000,"distinct":true,"filters":_vm.getFilters(_vm.school, _vm.question, _vm.exam)}}),_vm._v(" "),_c('ais-search-box',{attrs:{"placeholder":"Recherche une formation","autofocus":true,"show-loading-indicator":true,"class-names":{
      'ais-SearchBox-form': 'cover__search-bar-programs',
      'ais-SearchBox-input': 'search-bar search-bar__products',
      'ais-SearchBox-submit': 'btn btn--big btn--primary search-bar__button',
      'ais-SearchBox-reset': 'hidden'
    }}},[_c('div',{attrs:{"slot":"submit-icon"},slot:"submit-icon"},[_c('i',{staticClass:"fas fa-search text--white fa-flip-horizontal"})])]),_vm._v(" "),_c('ais-panel',[_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
return _c('div',{staticClass:"products"},[_c('div',{staticClass:"flex flex--wrap flex--between"},_vm._l((items),function(item){return _c('div',{key:item.objectID,staticClass:"school__small"},[_c('school',{attrs:{"establishment":item,"break-size":20,"track":true,"track-type":"Program"}})],1)}),0)])}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }